import React from "react";
import { servicesList } from "../data";

export default function Services() {
  return (
    <section id="ts-service-area" className="ts-service-area pb-0">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <h2 className="section-title">We Are Specialists In</h2>
            <h3 className="section-sub-title">What We Do</h3>
          </div>
        </div>
        {/*/ Title row end */}
        <div className="row">
          {servicesList.map(service => {
            return (
              <div className="col-lg-4">
                <div className="ts-service-box d-flex">
                  <div className="ts-service-box-img">
                    <img
                      loading="lazy"
                      height="48px"
                      src={service.iconImage}
                      alt={`${service.title} Icon`}
                    />
                  </div>
                  <div className="ts-service-box-info">
                    <h3 className="service-box-title">
                      <a href={service.link}>{service.title}</a>
                    </h3>
                    <p>{service.overview}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {/* Col end */}
        </div>
        {/* Content row end */}
      </div>
      {/*/ Container end */}
    </section>
  );
}
