import React from "react";

const InputField = props => {
  const {
    name,
    errors,
    isRequired = false,
    label = "Input Label",
    ...extraProps
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {isRequired && <span className="text-danger">*</span>}
      </label>
      <input {...extraProps} name={name} required={isRequired} />
      {errors && errors[name] && <p className="text-danger">{errors[name]}</p>}
    </div>
  );
};

export default InputField;
