import React from "react";
import { useLocation } from "react-router-dom";

export default function ViewService(props) {
  const { service, serviceTitles } = props;

  const location = useLocation();
  const serviceTitle = location.pathname;

  return (
    <section id="main-container" className="main-container">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className="sidebar sidebar-left">
              <div className="widget">
                <h3 className="widget-title">Solutions</h3>
                <ul className="nav service-menu">
                  {serviceTitles &&
                    serviceTitles?.map(item => (
                      <li
                        key={item.link}
                        className={
                          item.link.includes(serviceTitle) ? "active" : ""
                        }
                      >
                        <a href={item.link}>{item.title}</a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            {/* Sidebar end */}
          </div>
          {/* Sidebar Col end */}
          {service && (
            <div className="col-xl-8 col-lg-8">
              <div className="content-inner-page">
                <h2 className="column-title mrt-0">{service?.title}</h2>
                <div className="row">
                  <div className="col-md-12">
                    <p>{service?.description}</p>
                  </div>
                  {/* col end */}
                </div>
                {/* 1st row end*/}
                <div className="gap-40" />
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="column-title-small">
                      What Makes Us Different
                    </h3>
                    <p>{service?.whatMakesDifferent?.text}</p>
                    <ul className="list-arrow">
                      {service?.whatMakesDifferent?.points?.map(point => (
                        <li>{point}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6 mt-5 mt-md-0">
                    <h3 className="column-title-small">You Should Know</h3>
                    <div
                      className="accordion accordion-group accordion-classic"
                      id="construction-accordion"
                    >
                      {service?.faqs?.map((faq, faqIndex) => {
                        return (
                          <div className="card" key={faqIndex}>
                            <div
                              className="card-header p-0 bg-transparent"
                              id={`heading${faqIndex}`}
                            >
                              <h2 className="mb-0">
                                <button
                                  className="btn btn-block text-left"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={`#collapse${faqIndex}`}
                                  aria-expanded={
                                    faqIndex === 0 ? "true" : "false"
                                  }
                                  aria-controls={`collapse${faqIndex}`}
                                >
                                  {faq.question}
                                </button>
                              </h2>
                            </div>
                            <div
                              id={`collapse${faqIndex}`}
                              className={`collapse ${
                                faqIndex === 0 ? "show" : ""
                              }`}
                              aria-labelledby={`heading${faqIndex}`}
                              data-parent="#construction-accordion"
                            >
                              <div className="card-body">{faq.answer}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/*/ Accordion end */}
                  </div>
                </div>
                {/*2nd row end */}
                <div className="gap-40" />
                <div className="call-to-action classic">
                  <div className="row align-items-center">
                    <div className="col-md-8 text-center text-md-left">
                      <div className="call-to-action-text">
                        <h3 className="action-title">
                          Interested with this service.
                        </h3>
                      </div>
                    </div>
                    {/* Col end */}
                    <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                      <div className="call-to-action-btn">
                        <a className="btn btn-primary" href="/contact">
                          Get a Quote
                        </a>
                      </div>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}
                </div>
                {/* Action end */}
              </div>
              {/* Content inner end */}
            </div>
          )}
          {/* Content Col end */}
        </div>
        {/* Main row end */}
      </div>
      {/* Container end */}
    </section>
  );
}
