import React from "react";
import { ContactForm } from "../components";

export default function Contact() {
  return (
    <section id="main-container" className="main-container">
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <h2 className="section-title">Reaching our Office</h2>
            <h3 className="section-sub-title">Find Our Location</h3>
          </div>
        </div>
        {/*/ Title row end */}
        <div className="row">
          <div className="col-md-4 mt-2">
            <div className="ts-service-box-bg text-center h-100">
              <span className="ts-service-icon icon-round">
                <i className="fas fa-map-marker-alt mr-0" />
              </span>
              <div className="ts-service-box-content">
                <h4>Visit Our Office</h4>
                <a
                  style={{ color: "inherit" }}
                  href="https://maps.google.com/maps/dir//Peepul+Centre+Orchardson+Ave+Leicester+LE4+6DP/@52.6451985,-1.1220055,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4877610e34fe73f1:0xbc24ef96c4f29dff"
                >
                  Room 6, Peepul Centre, Orchardson Ave, Leicester LE4 6DP
                </a>
              </div>
            </div>
          </div>
          {/* Col 1 end */}
          <div className="col-md-4 mt-2">
            <div className="ts-service-box-bg text-center h-100">
              <span className="ts-service-icon icon-round">
                <i className="fa fa-envelope mr-0" />
              </span>
              <div className="ts-service-box-content">
                <h4>Email Us</h4>
                <p>
                  <a
                    href="mailto:info@nmconstructionltd.uk"
                    style={{ color: "inherit" }}
                  >
                    info@nmconstructionltd.uk
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* Col 2 end */}
          <div className="col-md-4 mt-2">
            <div className="ts-service-box-bg text-center h-100">
              <span className="ts-service-icon icon-round">
                <i className="fa fa-phone-square mr-0" />
              </span>
              <div className="ts-service-box-content">
                <h4>Call Us</h4>
                <p>
                  <a href="tel:03338804550">0333 880 4550</a>
                </p>
              </div>
            </div>
          </div>
          {/* Col 3 end */}
        </div>
        {/* 1st row end */}
        <div className="gap-60" />
        <div className="google-map" id="map">
          <div className="google-map">
            <iframe
              title="contact-google-maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2420.8176081532656!2d-1.1240814233112544!3d52.6452081720943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877610e34fe73f1%3A0xbc24ef96c4f29dff!2sPeepul%20Centre!5e0!3m2!1sen!2suk!4v1696504021571!5m2!1sen!2suk"
              width="auto"
              height="500"
              style={{
                border: 0,
                width: "100%",
                let: 0,
                top: 0
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="gap-40" />
        <div className="row">
          <div className="col-md-12">
            <ContactForm />
          </div>
        </div>
        {/* Content row */}
      </div>
      {/* Container end */}
    </section>
  );
}
