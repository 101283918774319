import React from "react";
import { servicesList } from "../data";

export default function Services() {
  return (
    <section id="main-container" className="main-container pb-2">
      <div className="container">
        <div className="row">
          {servicesList.map(service => {
            return (
              <div className="col-lg-4 col-md-6 mb-5">
                <div className="ts-service-box">
                  <div className="ts-service-image-wrapper">
                    <img
                      loading="lazy"
                      className="w-100"
                      height="240"
                      src={service.overviewImage}
                      alt={service.title}
                    />
                  </div>
                  <div className="d-flex">
                    <div>
                      <h3 className="service-box-title">
                        <a href={service.link}>{service.title}</a>
                      </h3>
                      <p>{service.overview}</p>
                      <a
                        className="learn-more d-inline-block"
                        href={service.link}
                        aria-label="service-details"
                      >
                        <i className="fa fa-caret-right" /> Learn more
                      </a>
                    </div>
                  </div>
                </div>
                {/* Service1 end */}
              </div>
            );
          })}
        </div>
        {/* Main row end */}
      </div>
      {/* Container end */}
    </section>
  );
}
