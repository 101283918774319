import React, { useState } from "react";
import { InputField, TextareaField } from "../components";

function dataValidator(data, requirements) {
  const errors = {};

  // Iterate through each field in the requirements object
  for (let fieldName in requirements) {
    if (requirements.hasOwnProperty(fieldName)) {
      // If an error message is already found for the current field, break out of the loop
      if (errors[fieldName]) {
        break;
      }

      const fieldValue = data[fieldName];
      const fieldRules = requirements[fieldName]
        .split(",")
        .map(rule => rule.trim());

      // Iterate through each rule for the field
      for (let rule of fieldRules) {
        if (errors[fieldName]) {
          break;
        }

        // Extract rule name and parameters (if any)
        const [ruleName, ruleParams] = rule.includes(":")
          ? rule.split(":").map(part => part.trim())
          : [rule.trim(), null];

        // eslint-disable-next-line
        switch (ruleName.toLowerCase()) {
          case "required":
            if (!fieldValue) {
              errors[fieldName] = `${fieldName} is required.`;
            }
            break;
          case "email":
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (fieldValue && !emailRegex.test(fieldValue)) {
              errors[
                fieldName
              ] = `${fieldName} should be a valid email address.`;
            }
            break;
          case "fullname":
            const fullNameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
            if (fieldValue && !fullNameRegex.test(fieldValue)) {
              errors[
                fieldName
              ] = `${fieldName} should be a valid full name (First Last).`;
            }
            break;
          case "minlength":
            const minLength = parseInt(ruleParams);
            if (fieldValue && fieldValue.length < minLength) {
              errors[
                fieldName
              ] = `${fieldName} should be at least ${minLength} characters.`;
            }
            break;
          case "maxlength":
            const maxLength = parseInt(ruleParams);
            if (fieldValue && fieldValue.length > maxLength) {
              errors[
                fieldName
              ] = `${fieldName} should not exceed ${maxLength} characters.`;
            }
            break;
        }
      }
    }
  }

  if (Object.keys(errors).length === 0) {
    return { ok: true }; // Data is valid
  } else {
    return { ok: false, data, errors };
  }
}

const ContactForm = () => {
  const [error, setError] = useState({});
  const [succeeded, setSucceeded] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const data = new FormData(event.target);
      const validationRequirements = {
        name: "required, fullName, minlength: 5, maxlength: 50",
        email: "required, email, minlength: 6, maxlength: 320",
        subject: "required, minlength: 3, maxlength: 100",
        message: "required, minlength: 20, maxlength: 1000"
      };
      const validationData = {};
      for (var pair of data.entries()) {
        validationData[pair[0]] = pair[1];
      }
      const dataValidation = dataValidator(
        validationData,
        validationRequirements
      );
      if (!dataValidation.ok && dataValidation.errors) {
        setError(dataValidation.errors);
        throw Error("Please enter valid data!");
      }
      const response = await fetch(event.target.action, {
        method: event.target.method,
        body: data,
        headers: {
          Accept: "application/json"
        }
      });
      if (response.ok) {
        setSucceeded(true);
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      setSucceeded(false);
    }
  };

  if (succeeded) {
    return (
      <div className="thank-you alert alert-success" id="thankYouMessage">
        <h4 className="alert-heading">Thank You!</h4>
        <p>Your message has been successfully submitted.</p>
        <hr />
        <p className="mb-0">
          Click here to view{" "}
          <a href="/services" className="alert-link">
            our services
          </a>
          .
        </p>
      </div>
    );
  }

  return (
    <>
      <h3 className="column-title">We love to hear</h3>
      <form
        id="contact-form"
        onSubmit={handleSubmit}
        action="https://formspree.io/f/xgejprkg"
        method="POST"
      >
        <div className="error-container" />
        <div className="row">
          <div className="col-md-4">
            <InputField
              id="name"
              name="name"
              errors={error}
              className="form-control form-control-name"
              isRequired={true}
              label="Name"
              placeholder="Jon Doe"
              type="text"
            />
          </div>
          <div className="col-md-4">
            <InputField
              id="email"
              name="email"
              errors={error}
              className="form-control form-control-email"
              isRequired={true}
              label="Email"
              placeholder="jon.doe@gmail.com"
              type="email"
            />
          </div>
          <div className="col-md-4">
            <InputField
              id="subject"
              name="subject"
              errors={error}
              className="form-control form-control-subject"
              isRequired={true}
              label="Subject"
              placeholder="Subject"
            />
          </div>
        </div>
        <TextareaField
          id="message"
          name="message"
          errors={error}
          className="form-control form-control-message"
          isRequired={true}
          label="Message"
          placeholder="Describe your requirements"
          rows={10}
        />
        <div className="text-right">
          <br />
          <button
            disabled={isSubmitting}
            className="btn btn-primary solid blank"
            type="submit"
          >
            Send Message
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
