import React from "react";
import { useLocation } from "react-router-dom";

function NavLink(props) {
  const { text, href = "/" } = props;
  const { pathname } = useLocation();
  const isNavActive = href === pathname;

  return (
    <li className={`nav-item ${isNavActive ? "active" : ""}`}>
      <a className="nav-link" href={href}>
        {text}
      </a>
    </li>
  );
}

export default function Navbar() {
  return (
    <>
      <div id="top-bar" className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <ul className="top-info text-center text-md-left">
                <li>
                  <i className="fas fa-map-marker-alt" />{" "}
                  <p className="info-text">Orchardson Ave, Leicester LE4 6DP</p>
                </li>
              </ul>
            </div>
            {/*/ Top info end */}
            <div className="col-lg-6 col-md-6 top-social text-center text-md-right">
              <ul className="list-unstyled">
                <li>
                  Contact via WhatsApp
                  <a
                    className="text-primary"
                    title="Contact via WhatsApp"
                    href="https://wa.me/447459230027"
                  >
                    +44 7459 230027
                  </a>
                </li>
              </ul>
            </div>
            {/*/ Top social end */}
          </div>
          {/*/ Content row end */}
        </div>
        {/*/ Container end */}
      </div>
      <header id="header" className="header-one">
        <div className="bg-white">
          <div className="container">
            <div className="logo-area">
              <div className="row align-items-center">
                <div className="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                  <a className="d-block" href="/">
                    <img
                      style={{ height: "52px" }}
                      loading="lazy"
                      src="/images/logo.jpeg"
                      alt="NM Construction Ltd"
                    />
                  </a>
                </div>
                {/* logo end */}
                <div className="col-lg-9 header-right">
                  <ul className="top-info-box">
                    <li>
                      <div className="info-box">
                        <div className="info-box-content">
                          <p className="info-box-title">Call Us</p>
                          <a
                            className="info-box-subtitle"
                            href="tel:03338804550"
                          >
                            0333 880 4550
                          </a>
                          {/* <p className="info-box-subtitle">+44 7459 230027</p> */}
                        </div>
                      </div>
                    </li>
                    <li className="last">
                      <div className="info-box last">
                        <div className="info-box-content">
                          <p className="info-box-title">Email Us</p>
                          <a
                            className="info-box-subtitle"
                            href="mailto:info@nmconstructionltd.uk"
                          >
                            info@nmconstructionltd.uk
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="header-get-a-quote">
                      <a className="btn btn-primary" href="contact">
                        Get A Quote
                      </a>
                    </li>
                  </ul>
                  {/* Ul end */}
                </div>
                {/* header right end */}
              </div>
              {/* logo area end */}
            </div>
            {/* Row end */}
          </div>
          {/* Container end */}
        </div>
        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    id="navbar-collapse"
                    className="collapse navbar-collapse"
                  >
                    <ul className="nav navbar-nav mr-auto">
                      <NavLink text="Home" href="/" />
                      <NavLink text="About" href="/about" />
                      <NavLink text="Services" href="/services" />
                      <NavLink text="Contact" href="/contact" />
                    </ul>
                  </div>
                </nav>
              </div>
              {/*/ Col end */}
            </div>
            {/*/ Row end */}
            <div className="nav-search">
              <span id="search">
                <i className="fa fa-search" />
              </span>
            </div>
            {/* Search end */}
            <div className="search-block" style={{ display: "none" }}>
              <label htmlFor="search-field" className="w-100 mb-0">
                <input
                  type="text"
                  className="form-control"
                  id="search-field"
                  placeholder="Type what you want and enter"
                />
              </label>
              <span className="search-close">×</span>
            </div>
            {/* Site search end */}
          </div>
          {/*/ Container end */}
        </div>
        {/*/ Navigation end */}
      </header>
    </>
  );
}
