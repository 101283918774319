import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components";
import {
  LandingPage,
  ErrorPage,
  About,
  Services,
  ViewService,
  Contact
} from "./pages/index";
import { servicesList } from "./data";

const App = () => {
  const [serviceTitles, setServiceTitles] = useState(null);

  useEffect(() => {
    let extractedTitles = servicesList?.map(({ title, link }) => ({
      title,
      link
    }));
    setServiceTitles(extractedTitles);
    return () => {
      setServiceTitles(null);
    };
  }, [setServiceTitles]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        {servicesList.map(service => (
          <Route
            key={service.link}
            path={service.link}
            element={
              <ViewService service={service} serviceTitles={serviceTitles} />
            }
          />
        ))}
        <Route path="/services/*" element={<ErrorPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </Layout>
  );
};

export default App;
