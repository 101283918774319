import React from "react";
import { FactsCounter, Projects } from "../components";

export default function About() {
  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="column-title">Who We Are</h3>
              <p>
                Welcome to <strong>NM Construction Ltd</strong>, a leading
                provider of comprehensive construction site solutions in the UK.
                With a decade of experience in the industry, we have built a
                strong reputation for our commitment to excellence, reliability,
                and customer satisfaction.
              </p>
              {/* <blockquote> */}
              <p>
                At <strong>NM Construction Ltd</strong>, we understand the
                unique challenges that contractors face when setting up
                construction sites.{" "}
              </p>
              {/* </blockquote> */}
              <p>
                Our mission is to simplify the entire process and provide
                exceptional support, allowing our clients to focus on their core
                business of building success.
              </p>
              <p>
                We take pride in our professionalism, reliability, and
                commitment to customer satisfaction. Our team is responsive,
                efficient, and always ready to go the extra mile to meet our
                clients’ needs. Whether you’re a contractor, developer, or end
                user, we are here to support you at every step of the
                construction process.
              </p>
            </div>
            {/* Col end */}
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div id="page-slider" className="page-slider small-bg">
                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/slide-page1.jpg)"
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h2 className="box-slide-title">Leadership</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Item 1 end */}
                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/slide-page2.jpg)"
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h2 className="box-slide-title">Relationships</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Item 1 end */}
                <div
                  className="item"
                  style={{
                    backgroundImage: "url(images/slider-pages/slide-page3.jpg)"
                  }}
                >
                  <div className="container">
                    <div className="box-slider-content">
                      <div className="box-slider-text">
                        <h2 className="box-slide-title">Performance</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Item 1 end */}
              </div>
              {/* Page slider end*/}
            </div>
            {/* Col end */}
          </div>
          {/* Content row end */}
        </div>
        {/* Container end */}
      </section>
      <FactsCounter />
      <Projects />
    </>
  );
}
