import React from "react";
import { FactsCounter, Services, Projects, Newsletter } from "../components";
export default function LandingPage() {
  return (
    <>
      <div className="banner-carousel banner-carousel-1 mb-0">
        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(images/slider-main/bg1.jpg)" }}
        >
          <div className="slider-content">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-md-12 text-center">
                  <h2 className="slide-title" data-animation-in="slideInLeft">
                    17 Years of excellence in
                  </h2>
                  <h3
                    className="slide-sub-title"
                    data-animation-in="slideInRight"
                  >
                    IT &amp; Construction Industry
                  </h3>
                  <p data-animation-in="slideInLeft" data-duration-in="1.2">
                    <a href="/services" className="slider btn btn-primary">
                      Our Services
                    </a>
                    <a
                      href="/contact"
                      className="slider btn btn-primary border"
                    >
                      Contact Now
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(images/slider-main/bg2.jpg)" }}
        >
          <div className="slider-content text-left">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-md-12">
                  <h2
                    className="slide-title-box"
                    data-animation-in="slideInDown"
                  >
                    World Class Service
                  </h2>
                  <h3 className="slide-title" data-animation-in="fadeIn">
                    When Service Matters
                  </h3>
                  <h3
                    className="slide-sub-title"
                    data-animation-in="slideInLeft"
                  >
                    Your Choice is Simple
                  </h3>
                  <p data-animation-in="slideInRight">
                    <a
                      href="/services"
                      className="slider btn btn-primary border"
                    >
                      Our Services
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="banner-carousel-item"
          style={{ backgroundImage: "url(images/slider-main/bg3.jpg)" }}
        >
          <div className="slider-content text-right">
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-md-12">
                  <h2 className="slide-title" data-animation-in="slideInDown">
                    Meet Our Engineers
                  </h2>
                  <h3 className="slide-sub-title" data-animation-in="fadeIn">
                    We believe sustainability
                  </h3>
                  <p
                    className="slider-description lead"
                    data-animation-in="slideInRight"
                  >
                    We will deal with your failure that determines how you
                    achieve success.
                  </p>
                  <div data-animation-in="slideInLeft">
                    <a
                      href="/contact"
                      className="slider btn btn-primary"
                      aria-label="contact-with-us"
                    >
                      Get Free Quote
                    </a>
                    <a
                      href="/about"
                      className="slider btn btn-primary border"
                      aria-label="learn-more-about-us"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="call-to-action-box no-padding">
        <div className="container">
          <div className="action-style-box">
            <div className="row align-items-center">
              <div className="col-md-8 text-center text-md-left">
                <div className="call-to-action-text">
                  <h3 className="action-title">We understand your needs</h3>
                </div>
              </div>
              {/* Col end */}
              <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                <div className="call-to-action-btn">
                  <a className="btn btn-dark" href="/contact">
                    Request Quote
                  </a>
                </div>
              </div>
              {/* col end */}
            </div>
            {/* row end */}
          </div>
          {/* Action style box */}
        </div>
        {/* Container end */}
      </section>
      {/* Action end */}
      <section id="ts-features" className="ts-features">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ts-intro">
                <h2 className="into-title">About Us</h2>
                <h3 className="into-sub-title">We deliver landmark projects</h3>
                <p>
                  Our vision is to be the leading provider of comprehensive IT
                  &amp; construction site solutions. We strive to revolutionise
                  the industry by offering a one-stop destination where
                  contractors and end users can find everything they need to set
                  up and secure their construction sites.
                </p>
              </div>
              {/* Intro box end */}
              <div className="gap-20" />
              <div className="row">
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-trophy" />
                    </span>
                    <div className="ts-service-box-content">
                      <h3 className="service-box-title">
                        We've a reputation for excellence
                      </h3>
                    </div>
                  </div>
                  {/* Service 1 end */}
                </div>
                {/* col end */}
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-sliders-h" />
                    </span>
                    <div className="ts-service-box-content">
                      <h3 className="service-box-title">
                        We Build Partnerships
                      </h3>
                    </div>
                  </div>
                  {/* Service 2 end */}
                </div>
                {/* col end */}
              </div>
              {/* Content row 1 end */}
              <div className="row">
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-thumbs-up" />
                    </span>
                    <div className="ts-service-box-content">
                      <h3 className="service-box-title">
                        Guided by Commitment
                      </h3>
                    </div>
                  </div>
                  {/* Service 1 end */}
                </div>
                {/* col end */}
                <div className="col-md-6">
                  <div className="ts-service-box">
                    <span className="ts-service-icon">
                      <i className="fas fa-users" />
                    </span>
                    <div className="ts-service-box-content">
                      <h3 className="service-box-title">
                        A Team of Professionals
                      </h3>
                    </div>
                  </div>
                  {/* Service 2 end */}
                </div>
                {/* col end */}
              </div>
              {/* Content row 1 end */}
            </div>
            {/* Col end */}
            <div className="col-lg-6 mt-4 mt-lg-0">
              <h3 className="into-sub-title">Our Values</h3>
              <p>
                At the heart of our company values is a dedication to integrity,
                shaping our delivery of construction services and cutting-edge
                IT hardware solutions.
              </p>
              <div
                className="accordion accordion-group"
                id="our-values-accordion"
              >
                <div className="card">
                  <div
                    className="card-header p-0 bg-transparent"
                    id="headingOne"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Innovation
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#our-values-accordion"
                  >
                    <div className="card-body">
                      Fueled by a passion for progress, we integrate innovative
                      practices into our construction projects and IT hardware
                      offerings to stay at the forefront of industry
                      advancements.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header p-0 bg-transparent"
                    id="headingTwo"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Customer Service
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#our-values-accordion"
                  >
                    <div className="card-body">
                      At the heart of our values is a dedication to exceptional
                      customer service, ensuring that clients receive
                      personalized attention and satisfaction in both
                      construction and IT hardware endeavors.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header p-0 bg-transparent"
                    id="headingThree"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Integrity
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#our-values-accordion"
                  >
                    <div className="card-body">
                      Integrity is our compass, guiding principled operations in
                      delivering construction and IT hardware solutions that
                      clients can trust.
                    </div>
                  </div>
                </div>
              </div>
              {/*/ Accordion end */}
            </div>
            {/* Col end */}
          </div>
          {/* Row end */}
        </div>
        {/* Container end */}
      </section>
      <FactsCounter />
      <Services />
      <Projects />
      <Newsletter />
    </>
  );
}
