export const servicesList = [
  {
    title: "IT Hardware Solutions",
    link: "/services/it-hardware-solutions",
    overview:
      "Elevate your tech infrastructure, maximizing efficiency with our IT Hardware Solutions. Quality printers, powerful servers, desktops, and laptops are tailored for seamless performance.",
    overviewImage: "/images/services/hardware.jpg",
    iconImage: "/images/icon-image/it-hardware.png",
    description:
      "At NM Construction Ltd, we understand that the modern construction site demands more than just bricks and mortar. In an era where technology plays a crucial role in enhancing safety, efficiency, and overall project management, we are proud to offer specialized IT hardware and security camera installation services tailored to meet the unique needs of construction projects.",
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, we recognize that the modern construction landscape requires more than traditional building methods. In an age where technology is pivotal for safety, efficiency, and project management, our distinction lies in Comprehensive Technology Integration. Specializing in tailored IT hardware and security camera installation services, we ensure that each construction project benefits from cutting-edge solutions. What truly sets us apart:",
      points: [
        "Forward-Thinking Technology Solutions",
        "Customized IT Hardware Services",
        "Expert Security Camera Installations",
        "Focus on Efficiency and Safety",
        "Industry-Leading Technological Expertise",
        "Flexibility to Meet Project Needs",
        "Proactive Safety Measures",
        "Client-Centric Approach to Technology Integration"
      ]
    },
    images: [],
    faqs: [
      {
        question:
          "What IT hardware solutions are provided for construction sites?",
        answer:
          "We offer a range of IT hardware solutions designed to enhance efficiency on construction sites. This includes the setup of robust network infrastructures, configuration of optimized workstations, and the implementation of secure server solutions."
      },
      {
        question:
          "How does the integration of security camera systems contribute to the safety of construction projects?",
        answer:
          "Our specialized security camera installations provide comprehensive surveillance, contributing to enhanced safety on construction sites. Tailored camera setups, remote monitoring capabilities, and integration with access control systems ensure real-time vigilance and effective risk management."
      },
      {
        question:
          "Can the technology integration services be tailored to the specific needs of my construction project?",
        answer:
          "Absolutely. Our commitment is to meet the unique needs of each construction project. We provide customized technology integration services, adapting IT hardware and security camera solutions to fit the specific requirements and layout of your construction site."
      }
    ]
  },
  {
    title: "Printers",
    link: "/services/printers",
    overview:
      "High-quality printers provided as a managed service for reliable, cost effective, and hassle-free printing.",
    overviewImage: "/images/services/printer.jpg",
    iconImage: "/images/icon-image/printer.png",
    description:
      "Revolutionize your printing dynamics with NM Construction Ltd Print Solutions' Managed Print Services. Benefit from all-in-one printers and document centers, seamlessly supported at a fixed monthly rate. Our commitment spans your printer's entire journey—from procurement and expert installation to continuous support, detailed maintenance, and eventual upgrades. Ensure a stress-free printing ecosystem as we enhance efficiency and reduce costs. Propel your business forward with our trustworthy and streamlined managed print solutions.",
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, we understand that effective print management goes beyond the ordinary. In a realm where streamlined processes and cost-effective solutions are paramount, our distinct approach is rooted in Advanced Print Service Excellence. Focused on delivering unparalleled managed print services, our key differentiators include:",
      points: [
        "Innovative Print Management Solutions",
        "Tailored Print Hardware Expertise",
        "Precision in Document Center Setup",
        "Emphasis on Cost Efficiency",
        "Unmatched Print Technology Proficiency",
        "Adaptability to Unique Project Requirements",
        "Preemptive Safety Measures",
        "Client-Centric Technology Integration"
      ]
    },
    images: [],
    faqs: [
      {
        question:
          "What are the key benefits of opting for Managed Print Services?",
        answer:
          "Managed Print Services offer several benefits, including cost savings, improved efficiency, proactive maintenance, and centralized print management. It streamlines your printing infrastructure for optimal performance and convenience."
      },
      {
        question:
          "How can I determine the right type of printer for my business needs?",
        answer:
          "Choosing the right printer involves assessing your specific requirements, such as printing volume, color needs, and budget. Our experts can guideyou through a thorough assessment to recommend the most suitable printer tailored to your business needs. Factors like printing speed, quality, and additional features will also be considered for a comprehensive solution."
      },
      {
        question:
          "What measures are in place to ensure the security of my printing environment?",
        answer:
          "We prioritize the security of your printing environment. Our Managed Print Services include features such as user authentication, encryption, and secure printing options. These measures help safeguard sensitive information and ensure that your printing infrastructure aligns with the highest security standards."
      }
    ]
  },
  {
    title: "Servers",
    link: "/services/servers",
    overview:
      "Powerful servers that can be scaled as your business grows and are complemented with ongoing support and maintenance.",
    overviewImage: "/images/services/server.jpg",
    iconImage: "/images/icon-image/servers.png",
    description:
      "Unlock the potential of your business with our Server Solutions as a Service. Our powerful servers are designed to scale seamlessly as your business expands. Paired with dedicated support and maintenance, ensure robust performance and reliability for your critical operations.",
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, we understand that effective print management goes beyond the ordinary. In a realm where streamlined processes and cost-effective solutions are paramount, our distinct approach is rooted in Advanced Print Service Excellence. Focused on delivering unparalleled managed print services, our key differentiators include:",
      points: [
        "Innovative Print Management Solutions",
        "Tailored Print Hardware Expertise",
        "Precision in Document Center Setup",
        "Emphasis on Cost Efficiency",
        "Unmatched Print Technology Proficiency",
        "Adaptability to Unique Project Requirements",
        "Preemptive Safety Measures",
        "Client-Centric Technology Integration"
      ]
    },
    images: [],
    faqs: [
      {
        question: "What makes your servers scalable for business growth?",
        answer:
          "Our servers are equipped with scalable architecture, allowing easy expansion of resources as your business evolves. This flexibility ensures your server infrastructure aligns with the changing demands of your growing enterprise."
      },
      {
        question: "What kind of ongoing support is provided for the servers?",
        answer:
          "We offer comprehensive ongoing support, including proactive monitoring, troubleshooting, and regular maintenance. Our team is committed to ensuring the continuous optimal performance of your servers, minimizing downtime and disruptions."
      },
      {
        question:
          "Can your server solutions accommodate specific business needs?",
        answer:
          "Absolutely. Our server solutions are customizable to meet the unique requirements of your business. Whether it's increased storage, enhanced processing power, or specific configurations, we tailor our services to align with your distinct operational needs."
      },
      {
        question:
          "How do you ensure the security of the server infrastructure?",
        answer:
          "Security is paramount. Our server solutions incorporate robust security measures, including firewalls, encryption, and regular security audits. We prioritize the protection of your data and sensitive information, implementing industry-leading practices to safeguard your server environment."
      },
      {
        question:
          "What is the process for scaling up the server capacity as our business expands?",
        answer:
          "Scaling up is a seamless process. Our team assesses your growing needs, plans the necessary upgrades, and executes the expansion with minimal disruption. This ensures that your server capacity aligns with the evolving demands of your business, allowing for uninterrupted operations."
      }
    ]
  },
  {
    title: "Desktops & Laptops",
    link: "/services/desktops-and-laptops",
    overview:
      "Cutting-edge equipment from leading brands and complemented with ongoing support and maintenance.",
    overviewImage: "/images/services/laptop.jpg",
    iconImage: "/images/icon-image/desktops-and-laptops.png",
    description:
      "Unlock the full potential of your business with our Server Solutions as a Service. Tailored for scalability, our powerful servers seamlessly expand with your business. Backed by dedicated support and maintenance, ensure robust performance and reliability for your critical operations. Our services are designed to grow with you, providing a secure and scalable foundation for your evolving business needs. Experience the next level of efficiency and flexibility with our comprehensive Server Solutions as a Service.",
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, we understand that effective print management goes beyond the ordinary. In a realm where streamlined processes and cost-effective solutions are paramount, our distinct approach is rooted in Advanced Print Service Excellence. Focused on delivering unparalleled managed print services, our key differentiators include:",
      points: [
        "Innovative Print Management Solutions",
        "Tailored Print Hardware Expertise",
        "Precision in Document Center Setup",
        "Emphasis on Cost Efficiency",
        "Unmatched Print Technology Proficiency",
        "Adaptability to Unique Project Requirements",
        "Preemptive Safety Measures",
        "Client-Centric Technology Integration"
      ]
    },
    faqs: [
      {
        question: "What makes your servers scalable for business growth?",
        answer:
          "Our servers are equipped with scalable architecture, allowing easy expansion of resources as your business evolves. This flexibility ensures your server infrastructure aligns with the changing demands of your growing enterprise."
      },
      {
        question: "What kind of ongoing support is provided for the servers?",
        answer:
          "We offer comprehensive ongoing support, including proactive monitoring, troubleshooting, and regular maintenance. Our team is committed to ensuring the continuous optimal performance of your servers, minimizing downtime and disruptions."
      },
      {
        question:
          "Can your server solutions accommodate specific business needs?",
        answer:
          "Absolutely. Our server solutions are customizable to meet the unique requirements of your business. Whether it's increased storage, enhanced processing power, or specific configurations, we tailor our services to align with your distinct operational needs."
      },
      {
        question:
          "How do you ensure the security of the server infrastructure?",
        answer:
          "Security is paramount. Our server solutions incorporate robust security measures, including firewalls, encryption, and regular security audits. We prioritize the protection of your data and sensitive information, implementing industry-leading practices to safeguard your server environment."
      },
      {
        question:
          "What is the process for scaling up the server capacity as our business expands?",
        answer:
          "Scaling up is a seamless process. Our team assesses your growing needs, plans the necessary upgrades, and executes the expansion with minimal disruption. This ensures that your server capacity aligns with the evolving demands of your business, allowing for uninterrupted operations."
      }
    ]
  },
  {
    title: "Site Clearance and Preparation",
    link: "/services/site-clearance-and-preparation",
    overview:
      "Efficiently clearing construction sites, we manage waste, demolition, and excavation with environmental care and strict safety adherence. Your site will be ready for construction with our expert touch.",
    overviewImage: "/images/services/service1.jpg",
    iconImage: "/images/icon-image/construction-site-cleaning.png",
    description:
      "Preparing a construction site for building is a complex process that requires attention to detail and adherence to regulations. Our team handles site clearance and preparation efficiently, ensuring that the area is ready for construction to begin. We manage waste removal, demolition, and excavation, taking care of environmental considerations and adhering to health and safety guidelines. With our expertise, your construction site will be cleared and prepared to the highest standards.",
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, our commitment to excellence in site clearance and preparation is what truly sets us apart. We pride ourselves on a meticulous attention to detail, ensuring that every aspect of the process is handled with precision. Unlike other services, we offer comprehensive solutions that go beyond mere compliance. Our dedicated team manages waste removal, demolition, and excavation efficiently, all while prioritizing environmental considerations and strict adherence to health and safety guidelines. What truly distinguishes us:",
      points: [
        "Expertise Beyond Compliance",
        "Comprehensive End-to-End Solutions",
        "Environmental Stewardship",
        "Safety as Our Top Priority",
        "Client-Centric Approach",
        "Efficient Timeliness",
        "Thorough Site Clearance",
        "Assurance of Highest Standards"
      ]
    },
    images: [],
    faqs: [
      {
        question: "What does site clearance and preparation entail?",
        answer:
          "Our service involves a comprehensive process of clearing and preparing a construction site for building. This includes waste removal, demolition, and excavation, all managed with attention to detail and adherence to regulations."
      },
      {
        question:
          "How do you ensure environmental considerations are taken into account during site clearance?",
        answer:
          "Our team is committed to environmental responsibility. We implement measures to minimize environmental impact during site clearance, including proper waste disposal methods and adherence to environmental regulations."
      },
      {
        question:
          "What health and safety guidelines do you follow during site clearance and preparation?",
        answer:
          "The safety of our team and adherence to health and safety guidelines are top priorities. We follow industry-standard protocols to ensure a secure working environment, mitigating potential risks during site clearance and preparation."
      }
    ]
  },
  {
    title: "Hoarding, Fencing, and Gates",
    link: "/services/hoarding-fencing-and-gates",
    overview:
      "We provide tailored hoarding, fencing, and gate solutions for construction site safety, privacy, and protection. From temporary fencing for short-term projects to robust hoarding installations, we prioritize your site's security.",
    overviewImage: "/images/services/service2.jpg",
    iconImage: "/images/icon-image/hoarding-fencing-and-gates.png",
    description:
      "Creating a secure boundary around your construction site is crucial for safety, privacy, and protection. We offer a range of hoarding, fencing, and gate solutions tailored to your specific site requirements. Whether you need temporary fencing for short-term projects or robust hoarding installations for long-term construction, we have the expertise to deliver reliable solutions. Our team ensures that your site is properly enclosed and protected, providing peace of mind throughout the construction process.",
    images: [],
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, our dedication to securing construction sites stands out with a commitment to tailored solutions. Our expertise in hoarding, fencing, and gates goes beyond standard offerings, providing specialized options for diverse project needs. We understand the importance of flexibility, offering solutions ranging from temporary fencing for short-term projects to robust hoarding installations for long-term constructions. What truly distinguishes us:",
      points: [
        "Tailored Solutions for Varied Project Needs",
        "Expertise in Hoarding, Fencing, and Gates",
        "Commitment to Site Security",
        "Flexibility for Short-Term and Long-Term Projects",
        "Peace of Mind Throughout Construction",
        "Meticulous Site Enclosure",
        "Advanced Security Measures",
        "Proven Reliability in Delivering Solutions"
      ]
    },
    faqs: [
      {
        question:
          "Why is securing a construction site with hoarding, fencing, and gates important?",
        answer:
          "Securing your construction site is essential for safety, privacy, and protection. Our solutions are designed to create a secure boundary, ensuring a controlled and safe environment."
      },
      {
        question:
          "What types of projects are suitable for temporary fencing, and when would one opt for more robust hoarding solutions?",
        answer:
          "We assess the nature and duration of your project to recommend whether temporary fencing or robust hoarding installations are more suitable, ensuring that the solution aligns with your specific needs."
      },
      {
        question:
          "What are the benefits of choosing your hoarding, fencing, and gate solutions for construction site security?",
        answer:
          "Clients benefit from our expertise in creating secure construction site boundaries, providing peace of mind, and safeguarding against potential risks. Our solutions are reliable, durable, and tailored to meet the unique needs of each project."
      }
    ]
  },
  {
    title: "Welfare Facilities and Storage",
    link: "/services/welfare-facilities-and-storage",
    overview:
      "Enhance your construction team's well-being with our comprehensive welfare facilities, including site offices, canteens, toilet blocks, drying rooms, and secure storage solutions for organized, protected, and accessible equipment and materials.",
    overviewImage: "/images/services/service3.jpg",
    iconImage: "/images/icon-image/welfare-facilities-and-storage.png",
    description:
      "Supporting the well-being of your construction team is essential for productivity and morale. We provide comprehensive welfare facilities to create a comfortable and functional on-site environment. From site offices and canteens to toilet blocks and drying rooms, we supply and install all the necessary amenities. Additionally, we offer secure storage solutions to keep your equipment and materials organised, protected, and easily accessible.",
    images: [],
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, our commitment to enhancing the well-being of your construction team goes hand in hand with our comprehensive welfare facilities and storage solutions. Unlike conventional services, we go beyond providing basic amenities. Our focus is on creating a comfortable and functional on-site environment tailored to the unique needs of your project. From site offices and canteens to toilet blocks and drying rooms, we deliver a range of amenities with precision. What truly distinguishes us:",
      points: [
        "Tailored Well-being Solutions",
        "Comprehensive On-Site Amenities",
        "Focus on Productivity and Morale",
        "Expertise in Site Office Installations",
        "Innovative Canteen and Drying Room Solutions",
        "Advanced Toilet Block Facilities",
        "Secure and Organized Equipment Storage",
        "Accessibility and Protection of Materials"
      ]
    },
    faqs: [
      {
        question:
          "Can you customize welfare facilities based on the specific needs of our construction site?",
        answer:
          "Yes, our welfare facilities are customizable to meet the unique requirements of your construction site. We tailor solutions to ensure they align with your project's specific needs."
      },
      {
        question:
          "How do you address security concerns in the storage solutions you provide?",
        answer:
          "Our storage solutions are designed to be secure, keeping equipment and materials organized, protected, and easily accessible. We implement measures to address security concerns and safeguard your valuable assets."
      },
      {
        question:
          "What are the benefits of choosing your welfare facilities and storage solutions for construction projects?",
        answer:
          "Clients benefit from our comprehensive approach to welfare facilities and storage, promoting the well-being of construction teams and providing secure, organized, and accessible solutions for equipment and materials."
      }
    ]
  },
  {
    title: "Fire Systems",
    link: "/services/fire-systems",
    overview:
      "Prioritize safety on your construction site with our fire systems and equipment. Our team assesses your site, recommends tailored fire safety measures, and ensures compliance with regulations for a secure working environment.",
    overviewImage: "/images/services/service6.jpg",
    iconImage: "/images/icon-image/fire-systems.png",
    description:
      "Safety is paramount on any construction site. We offer a range of fire systems and equipment to mitigate the risk of fire hazards. Our team assesses your site and recommends the most suitable fire safety measures, including fire alarms, extinguishers, emergency lighting systems, and evacuation plans. We ensure compliance with fire safety regulations and create a safe working environment for everyone involved in the construction project.",
    images: [],
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, our commitment to safety is at the core of our fire systems services. What sets us apart is our proactive approach to mitigating fire hazards on construction sites. Unlike standard services, we don't just provide fire systems; we assess each site meticulously, tailoring our recommendations to your specific needs. From fire alarms and extinguishers to emergency lighting systems and evacuation plans, we offer a comprehensive suite of solutions. What truly distinguishes us:",
      points: [
        "Proactive Hazard Mitigation",
        "Meticulous Site Assessment",
        "Tailored Fire Safety Measures",
        "Comprehensive Range of Fire Systems",
        "Expertise in Fire Alarms and Extinguishers",
        "Precision in Emergency Lighting Systems",
        "Customized Evacuation Plans",
        "Stringent Compliance with Fire Safety Regulations"
      ]
    },
    faqs: [
      {
        question:
          "What fire systems and equipment do you offer for construction sites?",
        answer:
          "We provide a comprehensive range of fire systems, including fire alarms, extinguishers, emergency lighting systems, and evacuation plans, to mitigate the risk of fire hazards on construction sites."
      },
      {
        question:
          "Can you provide examples of successful implementation of fire systems on construction sites, showcasing their effectiveness in preventing and managing fire incidents?",
        answer:
          "We have a track record of successfully implementing fire systems on various construction projects. Examples and case studies can be shared to demonstrate the effectiveness of our systems in preventing and managing fire incidents."
      },
      {
        question:
          "What ongoing support and training do you offer to ensure that construction site personnel are well-prepared to handle fire emergencies using the installed systems?",
        answer:
          "We offer comprehensive training to construction site personnel on the proper use of our fire systems. Additionally, we provide ongoing support to ensure that personnel are well-prepared to handle fire emergencies, contributing to a safer working environment on construction sites."
      }
    ]
  },
  {
    title: "Builders Cleans and Site Maintenance",
    link: "/services/builders-cleans-and-site-maintenance",
    overview:
      "After construction, our professional cleaning team ensures a pristine site with thorough builders' cleans, removing debris and dust. We provide ongoing maintenance for repairs and upkeep, maintaining optimal site condition.",
    overviewImage: "/images/services/service4.jpg",
    iconImage: "/images/icon-image/builders-cleans-and-site-maintenance.png",
    description:
      "Once construction is complete, we understand the importance of leaving the site clean and ready for use. Our professional cleaning team provides thorough builders cleans, removing debris, dust, and any construction-related mess. We pay meticulous attention to detail to ensure that the site is left spotless and presentable. Additionally, we offer ongoing site maintenance services to address any repairs or upkeep requirements, ensuring that your site remains in optimal condition.",
    images: [],
    whatMakesDifferent: {
      text:
        "At NM Construction Ltd, our commitment extends beyond the construction phase with our Builders Cleans and Site Maintenance services. What sets us apart is our dedication to delivering a site that is not only clean but ready for immediate use. Our professional cleaning team ensures a thorough removal of debris, dust, and any construction-related mess, paying meticulous attention to detail for a spotless and presentable result. Moreover, our ongoing site maintenance services go beyond expectations, addressing repairs and upkeep requirements, ensuring your site remains in optimal condition. What truly distinguishes us:",
      points: [
        "Ready-for-Use Site Delivery",
        "Professional Cleaning Team Expertise",
        "Meticulous Debris and Dust Removal",
        "Comprehensive Builders Cleans",
        "Ongoing Site Maintenance Services",
        "Dedication to Site Upkeep",
        "Attention to Detail in Spotless Results",
        "Immediate Site Presentation"
      ]
    },
    faqs: [
      {
        question:
          "What does your builders clean service entail, and how thorough is the cleaning process?",
        answer:
          "Our builders clean service is comprehensive, involving the removal of debris, dust, and any construction-related mess. The cleaning process is meticulous, ensuring that the site is left spotless and presentable for immediate use."
      },
      {
        question:
          "Can you provide more details on your ongoing site maintenance services?",
        answer:
          "Our ongoing site maintenance services are designed to address any repairs or upkeep requirements after construction is complete. We ensure that your site remains in optimal condition through regular maintenance activities."
      },
      {
        question:
          "How do you ensure that the site is ready for use after the builders clean, and what measures are in place to guarantee cleanliness and safety?",
        answer:
          "Our builders clean process is thorough, with measures in place to ensure the site is not only clean but also safe for use. We pay meticulous attention to detail, addressing cleanliness and safety concerns to meet the highest standards."
      }
    ]
  }
];
