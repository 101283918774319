import React from "react";
import { Navbar, Footer } from "./";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      {children ? children : null}
      <Footer />
    </>
  );
};

export default Layout;
