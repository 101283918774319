import React, { useState, useEffect } from "react";
import { servicesList } from "../data";

export default function Footer() {
  const [services, setServices] = useState(null);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    let extractedTitles = servicesList.map(({ title, link }) => ({
      title,
      link
    }));
    setServices(extractedTitles);
    return () => {
      setServices(null);
    };
  }, []);
  return (
    <footer id="footer" className="footer bg-overlay">
      <div className="footer-main">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-6 footer-widget footer-about">
              <h3 className="widget-title">About Us</h3>
              <img
                loading="lazy"
                width="200px"
                className="footer-logo"
                src="/images/logo.jpeg"
                alt="NM Construction Ltd"
              />
              <p>
                If you have a project on the way and need a quote, or have any
                questions in general then please contact us!
              </p>
            </div>
            <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
              <h3 className="widget-title">Working Hours</h3>
              <div className="working-hours">
                We work 7 days a week, every day excluding major holidays.
                Contact us if you have an emergency, with our Hotline and
                Contact form.
                <br />
                <br /> Monday - Friday:{" "}
                <span className="text-right">10:00 - 16:00 </span>
                <br /> Saturday:{" "}
                <span className="text-right">12:00 - 15:00</span>
                <br /> Sunday and holidays:{" "}
                <span className="text-right">09:00 - 12:00</span>
              </div>
            </div>
            {/* Col end */}
            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
              <h3 className="widget-title">Services</h3>
              <ul className="list-arrow">
                {services &&
                  services.map(item => (
                    <li key={item.link}>
                      <a href={item.link}>{item.title}</a>
                    </li>
                  ))}
              </ul>
            </div>
            {/* Col end */}
          </div>
          {/* Row end */}
        </div>
        {/* Container end */}
      </div>
      {/* Footer main end */}
      <div className="copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright-info">
                <span>
                  Copyright © {currentYear}, NM Construction Ltd.
                  {/* Designed &amp; Developed by{" "}
                  <a href="Company Website">Company</a> */}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-menu text-center text-md-right">
                <ul className="list-unstyled">
                  <li>
                    <a href="/">Home Page</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="/services">View Services</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Row end */}
          <div
            id="back-to-top"
            data-spy="affix"
            data-offset-top={10}
            className="back-to-top position-fixed"
          >
            <button className="btn btn-primary" title="Back to Top">
              <i className="fa fa-angle-double-up" />
            </button>
          </div>
        </div>
        {/* Container end */}
      </div>
      {/* Copyright end */}
    </footer>
  );
}
